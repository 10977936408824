import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TaggedText from "../reusable/TaggedText";
import { formatFieldResult, formatResultBody } from "../utils";

const TextSearchResultCard = ({ result, hl }) => {
  const title = formatFieldResult(result.title, hl, 'title')
  const body = formatResultBody(result.body, hl);

  return (
    <Card sx={{ my: 3 }} raised>
      <CardContent>
        <Box display="flex">
          <Link
            href={result.url || result.link}
            sx={{ textDecorationLine: "none" }}
          >
            <Typography variant="h4" sx={{ fontWeight: 500 }} gutterBottom dangerouslySetInnerHTML={{ __html: title }} />
          </Link>
        </Box>
        <Typography variant="body1">{result.id}</Typography>
        <TaggedText label="Category" text={result.category} />
        <TaggedText label="Score" text={result.score} />
        {result.authors && <TaggedText label="Author" text={result.authors} />}
        <Box mt={4}>
          <Typography dangerouslySetInnerHTML={{ __html: body }} />
        </Box>
      </CardContent>
    </Card>
  );
};

TextSearchResultCard.propTypes = {
  result: PropTypes.object.isRequired,
  hl: PropTypes.object
};

export default TextSearchResultCard;
