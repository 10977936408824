import { useEffect, useState } from "react";

import SearchBar from "../components/search-page/SearchBar";
import { textSearch } from "../api/api";
import {
  createRequestBody,
  formatResponse,
} from "../components/search-page/textSearchUtils";
import {
  DescriptionTitle,
  DescriptionBody,
} from "../components/search-page/PageDescriptions";
import { COLLECTIONS } from "../components/search-page/constants";
import DescriptionBox from "../components/reusable/DescriptionBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, defaults } from "../components/search-page/searchBarSchema";
import Loading from "../components/reusable/Loading";
import TextSearchResults from "../components/search-page/TextSearchResults";
import i18n from 'i18next';

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [results, setResults] = useState(null);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accordion, setAccordion] = useState(true);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaults,
  });

  useEffect(() => {
    if (filters.length === 0) return;
    setFilters([]);
    filters.map((f) => setValue(f, undefined));
    // eslint-disable-next-line
  }, [watch("query")]);

  const doSearch = async (searchParams) => {
    setLoading(true);
    try {
      const body = createRequestBody(searchParams);
      const { data: searchResults } = await textSearch(body);
      const response = formatResponse(searchParams.collection, searchResults);

      if (searchResults.docs.length === 0) {
        setFilters([]);
        filters.map((f) => setValue(f, undefined));
      }

      accordion && setAccordion(false);
      setResults(response);
    } catch (error) {
      console.log(error);
      setResults({ docs: [] });
      setFilters([]);
    }
    setLoading(false);
  };

  const handleManualSearch = async (searchParams) => {
    searchParams.offset = 1;
    searchParams.filters = filters;

    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  const handleAutoSearch = (queryTerms, keywordSearch, collection) => {
    const searchParams = {
      query: queryTerms,
      keywordSearch: keywordSearch,
      collection: collection,
      offset: 1,
      filters: [],
    };

    setValue("query", queryTerms);
    setValue("keywordSearch", keywordSearch);
    setValue("collection", collection);

    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  const handleFacetClick = (checked, filter) => {
    const newFilters = checked
      ? [...filters, filter]
      : filters.filter((f) => f !== filter);

    setFilters(newFilters);
  };

  const handlePageClick = async (pageNum) => {
    const searchParams = { ...searchQuery, offset: pageNum };
    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  const handleExample1Click = () => {
    i18n.language === 'ja'
      ? handleAutoSearch("ツイッター", false, COLLECTIONS.LIVEDOOR)
      : handleAutoSearch("Coronavirus", false, COLLECTIONS.HUFFPOST)
  }
  
  const handleExample2Click = () => {
    i18n.language === 'ja' 
      ? handleAutoSearch("スマホ", true, COLLECTIONS.LIVEDOOR)
      : handleAutoSearch("Celebrity", true, COLLECTIONS.HUFFPOST)
  }
  const handleExample3Click = () => {
    i18n.language === 'ja'
      ? handleAutoSearch("スマホ", false, COLLECTIONS.LIVEDOOR)
      : handleAutoSearch("Celebrities", false, COLLECTIONS.HUFFPOST)
  }

  const handleExample4Click = () => {
    i18n.language === 'ja'
      ? handleAutoSearch("安く外食したい", false, COLLECTIONS.LIVEDOOR)
      : handleAutoSearch("Who is the US president?", false, COLLECTIONS.HUFFPOST)
  }
   
  const handleAccordionToggle = () => setAccordion(!accordion);

  return (
    <>
      <DescriptionBox
        expanded={accordion}
        onAccordionChange={handleAccordionToggle}
        title={<DescriptionTitle />}
        body={
          <DescriptionBody
            example1Search={handleExample1Click}
            example2Search={handleExample2Click}
            example3Search={handleExample3Click}
            example4Search={handleExample4Click}
          />
        }
      />

      <SearchBar
        control={control}
        errors={errors}
        watch={watch}
        onSearch={handleManualSearch}
        handleSubmit={handleSubmit}
      />

      {loading && <Loading />}

      {results && !loading && (
        <TextSearchResults
          searchQuery={searchQuery}
          results={results}
          control={control}
          onPageClick={handlePageClick}
          onFacetClick={handleFacetClick}
        />
      )}
    </>
  );
};

export default SearchPage;
