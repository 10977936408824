import { Box, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation('general')
  return (
    <Box
      id="loading"
      display="flex"
      flexDirection="column"
      height="50vh"
      justifyContent="center"
      alignItems="center"
      my={2}
    >
      <Typography pb={5} variant="h4">
        {t('loading')}
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
