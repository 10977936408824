import { Button, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";
import i18n from "../../i18n";

const Title = ({ t }) => (
  <Typography variant="h5" mb={1}>
    {i18n.t('welcome.title', { ns: 'textToImagePage' })}
  </Typography>
);

const Body = ({ t, onExampleClick }) => {
  const searches = [
    { text: t('welcome.example1'), value: '楽器を演奏する人たち' },
    { text: t('welcome.example2'), value: 'パーティーを楽しむ人' },
    { text: t('welcome.example3'), value: '電話をかける男性' },
    { text: t('welcome.example4'), value: '大海原をゆく舟' },
  ];

  return (
    <>
      <Typography variant="body1" mb={1} display="flex" alignItems="center">
        <Trans ns="textToImagePage" i18nKey="welcome.source" components={[<Link href="https://cocodataset.org/#home" target="_blank" />, <Link href="https://github.com/fastai/imagenette" target="_blank" />, <Link href="https://www.anlp.jp/proceedings/annual_meeting/2017/pdf_dir/P8-7.pdf" target="_blank" />]} />
      </Typography>
      <Typography variant="body1">{t('welcome.howTo')}</Typography>
      <Typography variant="body1" mb={2} gutterBottom>
        {t('welcome.description1')}
      </Typography>
      <Typography variant="body1" mb={2} gutterBottom>
        {t('welcome.examples')}
      </Typography>
      {searches.map((search) => (
        <Button
          variant="text"
          key={search.text}
          onClick={() => onExampleClick(search.value)}
        >
          {search.text}
        </Button>
      ))}
    </>
  );
};

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('textToImagePage')(Title)
export const DescriptionBody = withTranslation('textToImagePage')(Body)
