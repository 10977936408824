import styled from "@emotion/styled";
import { Box, Pagination, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import Faceting from "../reusable/Faceting";
import NoContent from "../reusable/NoContent"
import CarRelatedResultCard from "./CarRelatedResultCard";
import CarResultCard from "./CarResultCard";

const SearchInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CarResults = ({
    searchQuery,
    results,
    related,
    control,
    onPageClick,
    onFacetClick
}) => {
    const { t } = useTranslation('carSearchPage')
    const { query, offset } = searchQuery
    const { docs, numFound, facets } = results

    const totalPages = Math.ceil(results.numFound / 10)
    const handlePageClick = (_, value) => onPageClick(value)

    return (
        <Box mt={2}>
            {docs.length > 0 ? (
                <>
                    {facets && Object.keys(facets).length > 0 && (
                        <Faceting
                          facets={facets}
                          onFacetClick={onFacetClick}
                          control={control}
                          noFacetNums
                        />
                    )}
                    
                    <SearchInfoBox>
                        {related ? (
                            <Typography variant='h4'>
                                {t('results.related')}
                            </Typography>
                        ) : (
                            <Typography variant='h4'>
                                {t('results.query', { query: query })}
                            </Typography>
                        )}
                        <Typography variant='body1'>
                            {t('results.docs', { num: numFound })}
                        </Typography>
                    </SearchInfoBox>

                    {related ? docs.map((result, i) =>
                        i === 0 ? (
                            <Box mt={2} key={result.id}>
                                <CarRelatedResultCard key={result.id} result={result} />
                            </Box>
                        ) : (
                            <CarResultCard key={result.id} result={result} />
                        )
                    ) : docs.map(result => (
                        <CarResultCard key={result.id} result={result} />
                    ))}

                    {totalPages > 1 && !related && (
                        <Box display="flex" justifyContent="center" mb={1}>
                            <Pagination
                                count={totalPages}
                                variant="outlined"
                                size="large"
                                page={offset}
                                onChange={handlePageClick}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <NoContent>
                    <Trans ns="carSearchPage" i18nKey="results.none" values={{ query: query }} components={[ <strong /> ]} />
                </NoContent>
            )}
        </Box>
    )
}

export default CarResults 