export const COLLECTIONS = {
  HUFFPOST: "new_en_semantic",
  LIVEDOOR: "ldn_semantic",
};

export const MODEL = {
  HUFFPOST: "all-MiniLM-L6-v2",
  LIVEDOOR: "sonoisa/sentence-bert-base-ja-mean-tokens-v2",
};

export const LIVEDOOR_VECTOR = {
  label: "Body Vector",
  value: "body_vector",
};

export const HUFFPOST_VECTOR = {
  label: "Description Vector",
  value: "description_vector",
};
