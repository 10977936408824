import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { localizedRoute } from "../components/utils"

const NotFoundPage = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation('notFoundPage')

    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography fontSize='10em' fontWeight={500}>404</Typography>
            <Typography variant='h1' pb={1}>{t('title')}</Typography>
            <Typography variant='body1'>
                {t('description')}
            </Typography>
            <Box display='flex' justifyContent='center' my={2}>
                <Button variant='outlined' onClick={() => navigate(localizedRoute(i18n.language, '/'))}>
                    {t('returnHome')}
                </Button>
            </Box>
        </Box>
    )
}

export default NotFoundPage