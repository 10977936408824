import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'
import dummyImage from "../../static/dummy-200.png";

const DemoSummary = ({ demo }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("700"));

  const navigate = useNavigate();

  return (
    <Grid item xs={12} lg={6}>
      <Card elevation={2}>
        <CardActionArea
          sx={{ display: "flex", alignItems: "flex-start" }}
          onClick={() => navigate(demo.link)}
        >
          {!isSmall && (
            <CardMedia
              component="img"
              sx={{
                backgroundColor: "white",
                padding: 1,
                height: 200,
                width: 200,
              }}
              image={demo.image || dummyImage}
              alt="demo image"
            />
          )}
          <CardContent>
            <Box>
              <Typography variant="h3" marginBottom="0.5em" aria-label='demo title'>
                {demo.title}
              </Typography>
              <Typography fontSize="14px" aria-label='demo description'>{demo.description}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

DemoSummary.propTypes = {
  demo: PropTypes.object.isRequired
}

export default DemoSummary;
