import { useState } from "react";
import PropTypes from "prop-types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";
import { Controller } from "react-hook-form";

const Faceting = ({ facets, control, onFacetClick, noFacetNums }) => {
  const [tab, setTab] = useState(0);
  const [accordion, setAccordion] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAccordionChange = () => setAccordion(!accordion);
  const handleTabChange = (event, newValue) => {
    !accordion && setAccordion(true);
    event.stopPropagation();
    setTab(newValue);
  };
  const handleFacetClick = (event, filter) =>
    onFacetClick(event.target.checked, filter);

  return (
    <Accordion
      expanded={accordion}
      onChange={handleAccordionChange}
      sx={{ mb: 3 }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
        >
          {Object.keys(facets).map((facet) => (
            <Tab label={facet} key={facet} />
          ))}
        </Tabs>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {Object.entries(facets).map((facetGroup, i) => (
            <TabPanel value={tab} index={i} key={i}>
              {facetGroup[1].map((facet) => (
                <FormControlLabel
                  key={facet[0]}
                  label={noFacetNums ? `${facet[0]}` : `${facet[0]}: ${facet[1]}`}
                  control={
                    // Form values here are not used. Only connected this to the form
                    // to synchronously and simply reset all values on manualSearch
                    <Controller
                      name={`${facetGroup[0]}: ${facet[0]}`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Checkbox
                          size="small"
                          {...field}
                          checked={!!value}
                          onChange={(e) => {
                            onChange(e);
                            handleFacetClick(
                              e,
                              `${facetGroup[0]}: ${facet[0]}`
                            );
                          }}
                        />
                      )}
                    />
                  }
                />
              ))}
            </TabPanel>
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

Faceting.propTypes = {
  facets: PropTypes.object,
  control: PropTypes.object,
  onFacetClick: PropTypes.func.isRequired,
  noFacetNums: PropTypes.bool
}

export default Faceting;

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ pX: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
