import { Box } from "@mui/material"
import { useState } from "react"
import noImage from '../../static/no-image.png'

const Image = ({ image }) => {
  const [isError, setIsError] = useState(false)

  if (isError) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' width={250} height={129}>
        <img src={noImage} alt='none' />
      </Box>
    )
  }

  return (
    <img src={image} onError={() => setIsError(true)} alt='car_result' width={250} height={129} />
  )
} 

export default Image