import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, ImageList, Typography, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ImageResultsCard from "./ImageResultsCard";
import NoContent from "./NoContent";
import PropTypes from 'prop-types' 

const SearchInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ImageResults = ({ searchQuery, results, imageSearch, patent }) => {
  const { t } = useTranslation('general')
  const theme = useTheme();

  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = lgScreen ? 5 : mdScreen ? 4 : smScreen ? 2 : 3;

  const { docs, numFound } = results;

  return (
    <Box mt={2}>
      {docs.length > 0 ? (
        <>
          <SearchInfoBox>
            <Typography variant="h4">
              {t('imageResults.query')} {searchQuery ? `"${searchQuery}"` : t('imageResults.image')}
            </Typography>
            <Typography variant="body1">
              {t('imageResults.docs', { num: numFound })}
            </Typography>
          </SearchInfoBox>
          <ImageList variant="masonry" cols={columns} gap={8}>
            {results.docs.map((result) => (
              <ImageResultsCard
                key={result.id}
                result={result}
                onImageSearch={imageSearch}
                patent={patent}
              />
            ))}
          </ImageList>
        </>
      ) : (
          <NoContent>
            <Trans
              ns="general"
              i18nKey="imageResults.none"
              components={[<strong />]}
              values={{ query: searchQuery ? searchQuery : t('imageResults.image') }}
            />
          </NoContent>
      )}
    </Box>
  );
};

ImageResults.propTypes = {
  searchQuery: PropTypes.object,
  results: PropTypes.object,
  imageSearch: PropTypes.func,
  patent: PropTypes.bool,
}

export default ImageResults;
