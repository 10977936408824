import styled from "@emotion/styled";
import { Box, Pagination, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Faceting from "../reusable/Faceting";
import NoContent from "../reusable/NoContent";
import PropTypes from 'prop-types'
import ManualSearchResultCard from "./ManualSearchResultCard";

const SearchInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ManualSearchResults = ({
  searchQuery,
  results,
  control,
  onFacetClick,
  onPageClick,
}) => {
  const { t } = useTranslation('solrManualPage')
  
  const { query, offset, filters } = searchQuery;
  const { docs, numFound, facets, hl } = results;

  const totalPages = Math.ceil(results.numFound / 10);

  const handlePageClick = (_, value) => onPageClick(value);

  return (
    <Box mt={2}>
      {docs.length > 0 ? (
        <>
          {facets && Object.keys(facets).length > 0 && (
            <Faceting
              facets={facets}
              onFacetClick={onFacetClick}
              control={control}
              noFacetNums
            />
          )}

          <SearchInfoBox>
            <Typography variant="h4">
              {t('results.query', { query: query })}
            </Typography>

            <Typography variant="body1">
              {t('results.docs', { num: numFound })}
            </Typography>
          </SearchInfoBox>

          {docs.map((result, i) => (
            <ManualSearchResultCard
              key={i}
              result={result}
              hl={hl?.[result.id]}
            />
          ))}

          {totalPages > 1 && (
            <Box display="flex" justifyContent="center" mb={1}>
              <Pagination
                count={totalPages}
                variant="outlined"
                size="large"
                page={offset}
                onChange={handlePageClick}
              />
            </Box>
          )}
        </>
      ) : (
        <NoContent>
          <Trans ns="textSearchPage" i18nKey="results.none" values={{ query: query }} components={[<strong />]} />
          {filters.length > 0 && (
            <Typography component="p" variant="caption">
              {t('results.filterReset')}
            </Typography>
          )}
        </NoContent>
      )}
    </Box>
  );
};

ManualSearchResults.propTypes = {
  searchQuery: PropTypes.object,
  results: PropTypes.object,
  control: PropTypes.object,
  onFacetClick: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
}

export default ManualSearchResults;
