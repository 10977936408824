import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { COLLECTIONS } from "./constants";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const selectStyle = (theme) => ({
  marginRight: "2em",
  marginTop: 0,
  [theme.breakpoints.down("850")]: {
    marginLeft: 0,
    marginTop: "0.5em",
  },
});

const boxStyle = (theme) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "1em",
  marginBottom: "1.5em",
  [theme.breakpoints.down("850")]: {
    flexDirection: "column",
  },
});

const SearchBar = ({ control, errors, handleSubmit, onSearch }) => {
  const { t } = useTranslation('textSearchPage')
  const onSubmit = (formData) => onSearch(formData);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" mt={2}>
        <Controller
          name="query"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon style={{ marginRight: "0.5em" }} />,
              }}
            />
          )}
        />
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="submit"
          sx={{
            width: 120,
            borderStartStartRadius: 0,
            borderEndStartRadius: 0,
            boxShadow: "none",
          }}
        >
          Search
        </Button>
      </Box>
      <Box sx={boxStyle}>
        <FormControl size="small" sx={selectStyle} error={!!errors?.collection}>
          <InputLabel id="collection-select">{t('search.collection')}</InputLabel>
          <Controller
            name="collection"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="collection-select"
                defaultValue=""
                label={t('search.collection')}
                sx={{ width: 250 }}
              >
                <MenuItem value={COLLECTIONS.LIVEDOOR} aria-label='livedoor'>
                  {t('search.livedoor')} 🇯🇵
                </MenuItem>
                <MenuItem value={COLLECTIONS.HUFFPOST} aria-label='huffpost'>{t('search.huffpost')} 🇬🇧</MenuItem>
              </Select>
            )}
          />

          {!!errors?.collection && (
            <FormHelperText>{t(errors?.collection?.message)}</FormHelperText>
          )}
        </FormControl>

        <Controller
          name="keywordSearch"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <FormControlLabel
              label={t('search.keyword')}
              sx={selectStyle}
              control={
                <Checkbox
                  {...field}
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
            />
          )}
        />
      </Box>
    </form>
  );
};

SearchBar.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
