import { useState } from "react";

import { useTheme } from "@emotion/react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from '@mui/icons-material/Language';
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../static/kandasearch-logo.svg";
import { useTranslation } from "react-i18next";
import { localeChangePath, localizedRoute } from "./utils";

const TopBar = () => {
  const { t, i18n } = useTranslation('general')
  const location = useLocation()
  const navigate = useNavigate();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [drawer, setDrawer] = useState(false);

  const swapLocalization = () => {
    const localizedPath = localeChangePath(i18n.language, location.pathname)
    navigate(localizedPath)
    if (smallScreen) toggleDrawer()
  }

  const menuItems = [
    { name: t('topbar.home'), url: localizedRoute(i18n.language, "/") },
    { name: t('topbar.text'), url: localizedRoute(i18n.language, "/text-search")},
    { name: t('topbar.image'), url: localizedRoute(i18n.language, "/image-search")},
    { name: t('topbar.textToImage'), url: localizedRoute(i18n.language, "/text-to-image")},
    { name: t('topbar.idiom'), url: localizedRoute(i18n.language, "/idioms-search")},
    { name: t('topbar.pediatrics'), url: localizedRoute(i18n.language, "/pediatrics-search") },
    { name: t('topbar.car'), url: localizedRoute(i18n.language, "/car-search")},
    { name: t('topbar.solrManual'), url: localizedRoute(i18n.language, "/solr-manual")},
    { name: t('topbar.patentData'), url: localizedRoute(i18n.language, "/patent-image-search")}
  ];

  const toggleDrawer = () => setDrawer(!drawer);
  const handlePageChange = (page) => {
    navigate(page);
    toggleDrawer();
  };


  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.background.dark, mb: 2 }}
      elevation={3}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          color="white"
          px={1}
          py={1}
          sx={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "https://kandasearch.com/")}
        >
          <Typography variant="caption" pr={0.5}>
            Powered By
          </Typography>
          <Avatar
            alt="logo"
            src={logo}
            sx={{ height: 50, width: 50, pr: 0.5 }}
          />
          <Typography variant="body2">KandaSearch</Typography>
        </Box>
        <Box>
          {!smallScreen && (
            <Button variant="text"
              sx={{ mx: 1, color: "white", "&:last-child": { mr: 3 } }}
              onClick={swapLocalization}>
              <LanguageIcon />
              <Typography variant='button' sx={{ pl: 1 }}>{t('topbar.language')}</Typography>
            </Button>
          )}
          <>
              <IconButton
                sx={{ color: "white", marginRight: "1em" }}
                onClick={toggleDrawer}
              >
                <MenuIcon fontSize="large" />
              </IconButton>

              <Drawer
                anchor="right"
                open={drawer}
                onClose={toggleDrawer}
                PaperProps={{
                  sx: {
                    backgroundColor: theme.palette.background.dark,
                    color: "white",
                  },
                }}
              >
                <List disablePadding>
                  {menuItems.map((item) => (
                    <Box key={item.name}>
                      <ListItem disablePadding sx={{ width: 300, height: 65 }}>
                        <ListItemButton
                          onClick={() => handlePageChange(item.url)}
                        >
                          <ListItemText>
                            <Typography>{item.name}</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{ backgroundColor: "white", opacity: "0.25" }}
                      />
                    </Box>
                  ))}
                    <Box>
                      <ListItem disablePadding sx={{ width: 300, height: 65 }}>
                        <ListItemButton
                          onClick={swapLocalization}
                        >
                          <LanguageIcon />
                          <ListItemText sx={{ ml: 1 }}>
                            <Typography>{t('topbar.language')}</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{ backgroundColor: "white", opacity: "0.25" }}
                      />
                    </Box>
                </List>
              </Drawer>
            </>
        </Box>
      </Box>
    </AppBar>
  );
};

export default TopBar;
