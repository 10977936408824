import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const TopPageLayout = ({ language }) => {  
  const { i18n } = useTranslation()

  useEffect(() => {
    if (language === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('ja')
    }
  // eslint-disable-next-line
  }, [language])

  return <Outlet />
};

export default TopPageLayout;
