import { Container } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import TopBar from "./TopBar";

const DemoLayout = ({ language }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (language === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('ja')
    }
  // eslint-disable-next-line
  }, [language])

  return (
    <>
      <ScrollToTop />
      <TopBar />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export default DemoLayout;
