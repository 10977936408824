import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TaggedText from "../reusable/TaggedText";
import { formatFieldResult, formatResultBody } from "../utils";

const ManualSearchResultCard = ({ result }) => {
  const title = formatFieldResult(result.title, null, 'title')
  const body = formatResultBody(result.body, null);

  return (
    <Card sx={{ my: 3 }} raised>
      <CardContent>
        <Box display="flex">
          <Link
            href={result.url || result.link}
            target="_blank"
            sx={{ textDecorationLine: "none" }}
          >
            <Typography variant="h4" sx={{ fontWeight: 500 }} gutterBottom dangerouslySetInnerHTML={{ __html: title }} />
          </Link>
        </Box>
        <TaggedText label="Category" text={result.category} />
        <Box mt={2}>
          <Typography>
            {body.length < 200 ? body : `${body.substring(0, 200)}...`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ManualSearchResultCard.propTypes = {
  result: PropTypes.object.isRequired,
  hl: PropTypes.array
};

export default ManualSearchResultCard;
