import styled from "@emotion/styled";
import { Box, Pagination, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import NoContent from "../reusable/NoContent";
import IdiomsResultCard from "./IdiomsResultCard";
import PropTypes from 'prop-types'

const SearchInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const IdiomsResults = ({ searchQuery, results, onPageClick }) => {
  const { t } = useTranslation('idiomSearchPage')

  const { query, keywordSearch, offset } = searchQuery;
  const { docs, numFound } = results;

  const totalPages = Math.ceil(results.numFound / 10);

  const handlePageClick = (_, value) => onPageClick(value);

  return (
    <Box mt={2}>
      {docs.length > 0 ? (
        <>
          <SearchInfoBox>
            <Typography variant="h4">
              {t('results.query', { query: query })}
            </Typography>

            <Typography variant="body1">
              {t('results.docs', { num: numFound })}
            </Typography>
          </SearchInfoBox>

          {docs.map((result) => (
            <IdiomsResultCard key={result.id} result={result} />
          ))}

          {totalPages > 1 && keywordSearch && (
            <Box display="flex" justifyContent="center" mb={1}>
              <Pagination
                count={totalPages}
                variant="outlined"
                size="large"
                page={offset}
                onChange={handlePageClick}
              />
            </Box>
          )}
        </>
      ) : (
        <NoContent>
          <Trans ns='idiomSearchPage' i18nKey='results.none' values={{ query: query }} components={[
            <strong />
          ]} />
        </NoContent>
      )}
    </Box>
  );
};

IdiomsResults.propTypes = {
  searchQuery: PropTypes.object,
  results: PropTypes.object,
  onPageClick: PropTypes.func.isRequired
}

export default IdiomsResults;
