import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
});

export const textSearch = (body) => {
  return instance.post(`/search`, body);
};

export const idiomSearch = (body) => {
  return instance.post(`/idiom_search`, body);
};

export const pediatricsSearch = (body) => {
  return instance.post(`/kodomoiin`, body);
};

export const pediatricsTunedSearch = (body) => {
  return instance.post(`/kodomoiin_tuned`, body);
};

export const pediatricsRelatedSearch = (body) => {
  return instance.post(`/kodomoiin_related`, body);
};

export const pediatricsTunedRelatedSearch = (body) => {
  return instance.post(`/kodomoiin_tuned_related`, body);
};

export const textToImageSearch = (body) => {
  return instance.post(`/text_to_image`, body);
};

export const imageSearch = (body) => {
  return instance.post(`/image_search`, body, { timeout: 20000 });
};

export const patentImageSearch = (body) => {
  return instance.post(`/patent_image_search`, body )
}

export const carSearch = (body) => {
  return instance.post(`/car_search`, body)
}

export const carRelatedSearch = (body) => {
  return instance.post(`/car_related`, body, { timeout: 20000 })
}

export const solrManualSearch = (body) => {
  return instance.post(`/solr_manual_search`, body)
}
