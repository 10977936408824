import { Box, Button, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

const Title = ({ t }) => (
    <Typography variant="h5" mb={1}>
        {t('welcome.title')}
    </Typography>
)

const Body = ({ t, example1Search, example2Search, example3Search, example4Search }) => (
    <>
        <Typography variant="body1" mb={1}>
        <Trans ns="solrManualPage" i18nKey="welcome.intro" components={[
            <Link href="https://solr.apache.org/guide/solr/latest/index.html" target='_blank'/>
        ]} />
        </Typography>
        <Typography variant="body1">{t('welcome.howToUse')}</Typography>
        <Typography variant="body1">{t('welcome.description1')}</Typography>
        <Box my={2}>
            <Button variant='text' sx={{ mr: 1 }} onClick={example1Search}>{t('welcome.example1')}</Button>
            <Button variant='text' sx={{ mr: 1 }} onClick={example2Search}>{t('welcome.example2')}</Button>
            <Button variant='text' sx={{ mr: 1 }} onClick={example3Search}>{t('welcome.example3')}</Button>
            {t('welcome.example4') && (
                <Button variant='text' sx={{ mr: 1 }} onClick={example4Search}>{t('welcome.example4')}</Button>
            )}
        </Box>
        <Typography variant="body1">{t('welcome.description2')}</Typography>
    </>
)

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('solrManualPage')(Title)
export const DescriptionBody = withTranslation('solrManualPage')(Body)