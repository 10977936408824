import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { pediatricsTunedRelatedSearch, pediatricsTunedSearch } from "../api/api";
import Loading from "../components/reusable/Loading";
import PediatricsTunedResults from "../components/pediatrics-page/PediatricsTunedResults";
import SimpleSearchBar from "../components/reusable/SimpleSearchBar";
import { formatSearchTerm } from "../components/utils";

const PediatricsTunedRelatedPage = () => {
  const { docGroup, docId } = useParams();

  const [searchQuery, setSearchQuery] = useState({});
  const [results, setResults] = useState(null);
  const [filters, setFilters] = useState([]);
  const [related, setRelated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      query: "",
      keywordSearch: false,
    },
  });

  useEffect(() => {
    if (filters.length === 0) return;
    setFilters([]);
    filters.map((f) => setValue(f, undefined));
    // eslint-disable-next-line
  }, [watch("query")]);

  const handleRelatedSearch = async (docId) => {
    const body = { id: `${docGroup}/${docId}` };
    setRelated(true);
    setLoading(true);
    try {
      const { data: relatedResults } = await pediatricsTunedRelatedSearch(body);
      setResults(relatedResults);
    } catch (error) {
      setResults({ docs: [] });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!docId) return;
    handleRelatedSearch(docId);
    // eslint-disable-next-line
  }, [docId]);

  const doSearch = async (searchParams) => {
    setLoading(true);
    const { query, keywordSearch, offset, filters } = searchParams;
    const formattedSearchTerm = formatSearchTerm(query);

    const body = {
      q: formattedSearchTerm,
      keyword: keywordSearch,
      offset: offset,
      filter: filters,
    };

    try {
      const { data: pediatricsResults } = await pediatricsTunedSearch(body);
      if (pediatricsResults.docs.length === 0) {
        setFilters([]);
        filters.map((f) => setValue(f, undefined));
      }
      setResults(pediatricsResults);
    } catch (error) {
      console.log(error);
      setResults({ docs: [] });
      setFilters([]);
    }
    setLoading(false);
  };

  const handleManualSearch = async (searchParams) => {
    setRelated(false);
    searchParams.offset = 1;
    searchParams.filters = filters;

    setSearchQuery(searchParams);

    await doSearch(searchParams);
  };

  const handleFacetClick = (checked, filter) => {
    const newFilters = checked
      ? [...filters, filter]
      : filters.filter((f) => f !== filter);

    setFilters(newFilters);
  };

  const handlePageClick = (pageNum) => {
    const searchParams = { ...searchQuery, offset: pageNum };
    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  return (
    <>
      <SimpleSearchBar
        control={control}
        handleSubmit={handleSubmit}
        onSearch={handleManualSearch}
      />

      {loading && <Loading />}
      {results && !loading && (
        <PediatricsTunedResults
          searchQuery={searchQuery}
          results={results}
          related={related}
          onPageClick={handlePageClick}
          onFacetClick={handleFacetClick}
          control={control}
        />
      )}
    </>
  );
};

export default PediatricsTunedRelatedPage;
