import { Box, Button, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

export const Title = ({ t }) => (
  <Typography variant="h5" mb={1}>
    {t('welcome.title')}
  </Typography>
);

export const Body = ({ t, onExampleClick }) => {
  const searches = [
    { text: t('welcome.example1'), value: '努力はやがて報われる', keyword: false },
    { text: t('welcome.example2'), value: '過去の経験から学ぶ', keyword: false },
    { text: t('welcome.example3'), value: 'めっちゃ怒る', keyword: false },
    { text: t('welcome.example4'), value: 'ツイッター', keyword: false },
    { text: t('welcome.example5'), value: '人事異動', keyword: false }
  ]

  return (
    <>
      <Typography variant="body1" mb={1} display="flex" alignItems="center">
        <Trans ns='idiomSearchPage' i18nKey='welcome.source' components={[
          <Link href="https://sanabo.com/words/" target='_blank'/>,
        ]} />
      </Typography>
      <Typography variant="body1">
        {t('welcome.howTo')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('welcome.description1')}
      </Typography>
      <Typography vairant="body1" mb={2}>
        {t('welcome.description2')}
      </Typography>
      {searches.slice(0, 2).map((search) => (
        <Box key={search.text}>
          <Button
            variant="text"
            onClick={() => onExampleClick(search.value, search.keyword)}
          >
            {search.text}
          </Button>
        </Box>
      ))}
      <Typography vaiant="body1">
        <Trans ns='idiomSearchPage' i18nKey='welcome.description3' components={[
          <Button
            variant="text"
            sx={{ padding: 0 }}
            onClick={() => onExampleClick(searches[2].value, searches[2].keyword)}
          />
        ]} />
      </Typography>
      {searches.slice(3).map((search) => (
        <Box key={search.text}>
          <Button
            variant="text"
            onClick={() => onExampleClick(search.value, search.keyword)}
          >
            {search.text}
          </Button>
        </Box>
      ))}
    </>
  );
};

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('idiomSearchPage')(Title)
export const DescriptionBody = withTranslation('idiomSearchPage')(Body)
