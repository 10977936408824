import * as yup from "yup";

export const defaults = {
  query: "",
  keywordSearch: false,
  collection: "",
};

export const schema = yup.object().shape({
  query: yup.string(),
  keywordSearch: yup.bool(),
  collection: yup.string().required('search.collectionError'),
});


