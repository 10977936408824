import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const footer = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  paddingBottom: "1rem",
  color: "common.white",
  "& a": {
    color: "common.white",
  },
});

const gap = (theme) => ({
  padding: "2em",
  maxWidth: "1120px",
  margin: "auto",
  [theme.breakpoints.down("xs")]: {
    paddingBottom: "1em",
  },
});

const links = (theme) => ({
  paddingLeft: "1em",
  [theme.breakpoints.down("600")]: {
    marginTop: "1em",
    paddingLeft: 0,
  },
});

const footnotes = (theme) => ({
  paddingBottom: "1em",
  textAlign: "center",
  [theme.breakpoints.down("600")]: {
    textAlign: "left",
    paddingLeft: "2em",
  },
});

const Footer = () => {
  const { t } = useTranslation("topPage")
  return (
    <Box sx={footer}>
      <Grid container sx={gap}>
        <Grid container item>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">KandaSearch</Typography>
            <Typography variant="caption">
              {t('footer.copyright')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={links}>
            <Typography variant="caption" display="block" gutterBottom>
              <Link
                href="https://www.rondhuit.com/"
                aria-label="terms of services"
                target="_blank"
              >
                {t('footer.profile')}
              </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link
                href="https://kandasearch.com/policy/terms"
                aria-label="terms of services"
                target="_blank"
              >
                {t('footer.terms')}
              </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link
                href="https://kandasearch.com/policy/legal"
                aria-label="specified commercial transactions law"
                target="_blank"
              >
                {t('footer.legal')}
              </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link href="https://kandasearch.com/news/" aria-label="news" target="_blank">
                {t('footer.news')}
              </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link href="https://kandasearch.com/blogs/" aria-label="blog" target="_blank">
                {t('footer.blog')}
              </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link
                href="https://www.rondhuit.com/privacy.html"
                target="_blank"
              >
                {t('footer.privacy')}
                <OpenInNewIcon style={{ fontSize: 12, marginLeft: 5 }} />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={footnotes}>
        <Typography variant="caption" display="block">
          Apache and the Apache feather logo are trademarks of The Apache
          Software Foundation.
        </Typography>
        <Typography variant="caption" display="block">
          Apache Lucene, Solr, OpenNLP, Spark and their respective logos are
          trademarks of the Apache Software Foundation.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
