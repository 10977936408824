import ExpandMore from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import Image from "../reusable/Image"
import TaggedText from "../reusable/TaggedText"

const CarRelatedResultCard = ({ result }) => {
    const { t } = useTranslation('carSearchPage')

    return (
    <Accordion
      sx={{ my: 3 }}
      defaultExpanded={false}
      component={Paper}
      elevation={2}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">{t('related.original')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' gap={2}>
            <Image image={result.og_image} />
            <Box display='flex' flexDirection='column' flexGrow={1}>
            <Box display='flex' >
              <Link href={result.id} sx={{ textDecorationLine: "none" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: result.model }}
                />
              </Link>
            </Box>
            <TaggedText label="Maker" text={result.maker_en} />
            <TaggedText label="Model" text={result.model_en} />
            <TaggedText label="Score" text={result.score} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    )
}

export default CarRelatedResultCard