import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import searchTitle from "../../static/search-hero.jpg";

const Hero = () => {
  const { t } = useTranslation('topPage')
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: `linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
          ), url(${searchTitle})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundSize: "cover",
    height: "100%",
    color: "white",
    justifyContent: "center",
  };

  return (
    <Box sx={boxStyle} mt={-2}>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={600}
        textAlign="center"
      >
        <Typography
          variant={isSmallScreen ? "h2" : "h1"}
          fontWeight="bold"
          align="center"
          gutterBottom
          aria-label='title'
        >
          {t('hero.semantic')}
        </Typography>
        {!isSmallScreen && (
          <Typography variant="h2" pb={4} aria-label='subtitle'>
            {t('hero.subtitle')}
          </Typography>
        )}
        <Box mt={2}>
          <Typography px={2} id="caption" variant="body1" pb={2} aria-label='subtext 1'>
            {t('hero.subtext1')}
          </Typography>
          <Typography px={2} id="caption" variant="body1" aria-label='subtext 2'>
            {t('hero.subtext2')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
