import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoContent = ({ children }) => {
  return (
    <Box
      aria-label='no content'
      sx={{
        bgcolor: "grey.400",
        color: "white",
        padding: "4em",
        borderRadius: 1,
        my: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="h4">{children}</Typography>
    </Box>
  );
};

export default NoContent;
