import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const SimpleSearchBar = ({ control, handleSubmit, onSearch, noKeyword }) => {
  const { t } = useTranslation('general')
  const onSubmit = (formData) => onSearch(formData);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" my={2}>
        <Controller
          name="query"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon style={{ marginRight: "0.5em" }} />,
              }}
            />
          )}
        />
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="submit"
          sx={{
            width: 120,
            borderStartStartRadius: 0,
            borderEndStartRadius: 0,
            boxShadow: "none",
          }}
        >
          Search
        </Button>
      </Box>
      <Box mb={"1.5em"}>
        {!noKeyword && (
          <Controller
            name="keywordSearch"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <FormControlLabel
                label={t('simpleSearchBar.keyword')}
                control={
                  <Checkbox
                    {...field}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        )}
      </Box>
    </form>
  );
};

SimpleSearchBar.propTypes = {
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  noKeyword: PropTypes.bool,
};

export default SimpleSearchBar;
