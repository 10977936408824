import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from "react-chartjs-2"
import dummyImage from '../static/dummy-200.png'
import { createTableBody, createTableTitle, getOrCreateTooltip } from '../components/utils';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';


const ChartDemoPage = () => {

  const data = {
    datasets: [
      {
        type: 'scatter',
        label: 'A dataset',
        data: Array.from({ length: 100 }, () => ({
          x: Math.floor(Math.random() * 100),
          y: Math.floor(Math.random() * 100),
          name: 'Toyota Camry',
          img: dummyImage
        })),
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
      {
        type: 'bubble',
        label: 'Bubble',
        data: Array.from({ length: 5 }, () => ({
            x: Math.floor(Math.random() * 100),
            y: Math.floor(Math.random() * 100),
            r: Math.floor(Math.random() * 100)
        }))
      }
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          const { chart, tooltip } = context
          let tooltipEl = getOrCreateTooltip(chart)

          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Tooltip Content
          if (tooltip.body) {
            
            const tableHead = document.createElement('thead');
            createTableTitle(tableHead, tooltip.dataPoints[0])

            const tableBody = document.createElement('tbody');
            createTableBody(tableBody, tooltip.dataPoints[0])

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
              tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
          }

          const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = positionX + tooltip.caretX + 'px';
          tooltipEl.style.top = positionY + tooltip.caretY + 'px';
          tooltipEl.style.font = tooltip.options.bodyFont.string;
          tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
      }
    }
  }

    return (
      <Box>
        <Accordion>
          <AccordionSummary>Example</AccordionSummary>
          <AccordionDetails>
            <div>
              <Chart type='scatter' data={data} options={options} />
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
}

export default ChartDemoPage