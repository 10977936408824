import { Button, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

const Title = ({ t }) => (
  <Typography variant="h5" mb={1}>
    {t('welcome.title')}
  </Typography>
);

const Body = ({ t, onExampleClick }) => {
  const searches = [
    { text: t('welcome.example1'), value: '鼻づまりで赤ちゃんがよく眠れない', keyword: false },
    { text: t('welcome.example2'), value: '夜泣きがひどい', keyword: false },
    { text: t('welcome.example3'), value: '仕事と保育の両立について', keyword: false },
  ];

  return (
    <>
      <Typography variant="body1" mb={1} display="flex" alignItems="center">
        <Trans ns="pediatricsSearchPage" i18nKey='welcome.source' components={[
          <Link href="https://www.kodomo-iin.com/QA/QA.html" target="_blank" />
        ]} />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Trans ns="pediatricsSearchPage" i18nKey="welcome.description1" components={[
          <Link href="https://www.kodomo-iin.com/" target="_blank" />
        ]} />
      </Typography>
      <Typography variant="body1">
        {t('welcome.howTo')}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t('welcome.description2')}
      </Typography>
      {searches.map((search) => (
        <Button
          variant="text"
          sx={{ marginRight: "1em", marginBottom: "0.5em" }}
          key={search.text}
          onClick={() => onExampleClick(search.value, search.keyword)}
        >
          {search.text}
        </Button>
      ))}
      <Typography vaiant="body1">
        {t('welcome.description3')}
      </Typography>
    </>
  );
};

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('pediatricsSearchPage')(Title)
export const DescriptionBody = withTranslation('pediatricsSearchPage')(Body)
