import { ImageListItem } from "@mui/material";
import PropTypes from "prop-types";

const ImageResultsCard = ({ result, onImageSearch, patent }) => {
  
  const imgSrc = patent ? (
    `/images/patent/${result.id}`
  ) : (
    `/images/${result.id}`
  )
  
  return (
    <ImageListItem key={result.id} loading="lazy">
      <img
        alt={result.id}
        src={imgSrc}
        onClick={() => onImageSearch(imgSrc)}
      />
    </ImageListItem>
  );
};

ImageResultsCard.propTypes = {
  result: PropTypes.object.isRequired,
  onImageSearch: PropTypes.func.isRequired,
  patent: PropTypes.bool
};

export default ImageResultsCard;
