import * as yup from "yup";

export const defaults = {
  query: "",
  keywordSearch: false
};

export const schema = yup.object().shape({
  query: yup.string(),
  keywordSearch: yup.bool()
});