import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { pediatricsSearch } from "../api/api";
import {
  DescriptionBody,
  DescriptionTitle,
} from "../components/pediatrics-page/PageDescriptions";
import DescriptionBox from "../components/reusable/DescriptionBox";
import Loading from "../components/reusable/Loading";
import PediatricsResults from "../components/pediatrics-page/PediatricsResults";
import SimpleSearchBar from "../components/reusable/SimpleSearchBar";
import { formatSearchTerm } from "../components/utils";

const PediatricsPage = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [results, setResults] = useState(null);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accordion, setAccordion] = useState(true);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      query : "",
      keywordSearch: false,
    },
  });

  useEffect(() => {
    if (filters.length === 0) return;
    setFilters([]);
    filters.map((f) => setValue(f, undefined));
    // eslint-disable-next-line
  }, [watch("query")]);

  const doSearch = async (searchParams) => {
    setLoading(true);
    const { query, keywordSearch, offset, filters } = searchParams;
    const formattedSearchTerm = formatSearchTerm(query);

    const body = {
      q: formattedSearchTerm,
      keyword: keywordSearch,
      offset: offset,
      filter: filters,
    };

    try {
      const { data: pediatricsResults } = await pediatricsSearch(body);

      accordion && setAccordion(false);
      setResults(pediatricsResults);
      if (pediatricsResults.docs.length === 0) {
        setFilters([]);
        filters.map((f) => setValue(f, undefined));
      }
    } catch (error) {
      console.log(error);
      setResults({ docs: [] });
      setFilters([]);
    }
    setLoading(false);
  };

  const handleManualSearch = async (searchParams) => {
    searchParams.offset = 1;
    searchParams.filters = filters;

    setSearchQuery(searchParams);

    await doSearch(searchParams);
  };

  const handleAutoSearch = (queryTerms, keywordSearch) => {
    const searchParams = {
      query: queryTerms,
      keywordSearch: keywordSearch,
      offset: 1,
      filters: [],
    };

    setValue("query", queryTerms);
    setValue("keywordSearch", keywordSearch);

    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  const handleFacetClick = (checked, filter) => {
    const newFilters = checked
      ? [...filters, filter]
      : filters.filter((f) => f !== filter);

    setFilters(newFilters);
  };

  const handlePageClick = (pageNum) => {
    const searchParams = { ...searchQuery, offset: pageNum };
    setSearchQuery(searchParams);

    doSearch(searchParams);
  };

  const handleAccordionToggle = () => setAccordion(!accordion);

  return (
    <>
      <DescriptionBox
        expanded={accordion}
        onAccordionChange={handleAccordionToggle}
        title={<DescriptionTitle />}
        body={<DescriptionBody onExampleClick={handleAutoSearch} />}
      />

      <SimpleSearchBar
        control={control}
        handleSubmit={handleSubmit}
        onSearch={handleManualSearch}
      />

      {loading && <Loading />}
      {results && !loading && (
        <PediatricsResults
          searchQuery={searchQuery}
          results={results}
          onPageClick={handlePageClick}
          onFacetClick={handleFacetClick}
          control={control}
        />
      )}
    </>
  );
};

export default PediatricsPage;
