import image1 from '../../static/patent-search-images/search-image-1.jpg'
import image2 from '../../static/patent-search-images/search-image-2.png'
import image3 from '../../static/patent-search-images/search-image-3.png'
import image4 from '../../static/patent-search-images/search-image-4.png'
import image5 from '../../static/patent-search-images/search-image-5.png'
import image6 from '../../static/patent-search-images/search-image-6.png'
import image7 from '../../static/patent-search-images/search-image-7.png'
import image8 from '../../static/patent-search-images/search-image-8.png'
import image9 from '../../static/patent-search-images/search-image-9.png'

export const patentImageList = [
  {
    image: image1,
    text: 'welcome.image1',
  },
  {
    image: image2,
    text: 'welcome.image2',
  },
  {
    image: image3,
    text: 'welcome.image3',
  },
  {
    image: image4,
    text: 'welcome.image4',
  },
  {
    image: image5,
    text: 'welcome.image5',
  },
  {
    image: image6,
    text: 'welcome.image6',
  },
  {
    image: image7,
    text: 'welcome.image7',
  },
  {
    image: image8,
    text: 'welcome.image8',
  },
  {
    image: image9,
    text: 'welcome.image9',
  },
];