import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";  // Import useLocation and useNavigate
import { idiomSearch } from "../api/api";
import IdiomsResults from "../components/idioms-page/IdiomsResults";
import {
  DescriptionBody,
  DescriptionTitle,
} from "../components/idioms-page/PageDescriptions";
import DescriptionBox from "../components/reusable/DescriptionBox";
import Loading from "../components/reusable/Loading";
import SimpleSearchBar from "../components/reusable/SimpleSearchBar";
import { formatSearchTerm } from "../components/utils";

const IdiomsPage = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accordion, setAccordion] = useState(true);

  const location = useLocation();  // Get the current location
  const navigate = useNavigate();  // Get navigate for navigation

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      query: "",
      keywordSearch: false,
    },
  });

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("q");
    const keywordSearch = searchParams.get("keyword") === "true";
    const offset = parseInt(searchParams.get("offset") || "1", 10);

    if (query) {
      handleAutoSearch(query, keywordSearch, offset);
    }
  }, [location.search]);  // Run when the location.search changes

  const doSearch = async (searchParams) => {
    setLoading(true);
    try {
      const { query, keywordSearch, offset } = searchParams;
      const formattedSearchTerm = formatSearchTerm(query);

      const body = {
        q: formattedSearchTerm,
        keyword: keywordSearch,
        offset: offset,
      };

      const { data: idiomResults } = await idiomSearch(body);
      accordion && setAccordion(false);
      setResults(idiomResults);
    } catch (error) {
      console.log(error);
      setResults({ docs: [] });
    }
    setLoading(false);
  };

  const handleManualSearch = (searchParams) => {
    searchParams.offset = 1;
    setSearchQuery(searchParams);
    updateUrlParams(searchParams);  // Update URL with the search parameters
    doSearch(searchParams);
  };

  const handleAutoSearch = (query, keywordSearch = false, offset = 1) => {
    const searchParams = {
      query: query,
      keywordSearch: keywordSearch,
      offset: offset,
    };

    setValue("query", query);
    setValue("keywordSearch", keywordSearch);

    setSearchQuery(searchParams);
    updateUrlParams(searchParams);  // Update URL with the search parameters

    doSearch(searchParams);
  };

  const updateUrlParams = (searchParams) => {
    const { query, keywordSearch, offset } = searchParams;
    const newParams = new URLSearchParams();
    newParams.set("q", query);
    newParams.set("keyword", keywordSearch);
    newParams.set("offset", offset);

    navigate({ search: newParams.toString() });  // Update the URL
  };

  const handleAccordionToggle = () => setAccordion(!accordion);

  const handlePageClick = async (pageNum) => {
    const searchParams = { ...searchQuery, offset: pageNum };
    setSearchQuery(searchParams);
    updateUrlParams(searchParams);  // Update URL with the new page number
    doSearch(searchParams);
  };

  return (
    <>
      <DescriptionBox
        expanded={accordion}
        onAccordionChange={handleAccordionToggle}
        title={<DescriptionTitle />}
        body={<DescriptionBody onExampleClick={handleAutoSearch} />}
      />

      <SimpleSearchBar
        control={control}
        handleSubmit={handleSubmit}
        onSearch={handleManualSearch}
      />

      {loading && <Loading />}

      {results && !loading && (
        <IdiomsResults
          searchQuery={searchQuery}
          results={results}
          onPageClick={handlePageClick}
        />
      )}
    </>
  );
};

export default IdiomsPage;

