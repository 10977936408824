import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TaggedText from "../reusable/TaggedText";
import { formatFieldResult, localizedRoute } from "../utils";

const PediatricsResultCard = ({ result, hl }) => {
  const { t, i18n } = useTranslation('pediatricsSearchPage')
  
  const trimId = (docId) => {
    const splitId = docId.split("QA/");
    const id = splitId[splitId.length - 1].match(/.+?(?=\.html)/);
    return id[0];
  };

  const question = formatFieldResult(result.question, hl, "question");
  const answer = formatFieldResult(result.answer, hl, "answer");
  const title = formatFieldResult(result.title, hl, "title");

  return (
    <Card sx={{ my: 3 }} raised>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link href={result.id} sx={{ textDecorationLine: "none" }}>
            <Typography
              variant="h4"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Link>
          <Link href={localizedRoute(i18n.language, `/pediatrics-search/${trimId(result.id)}`)}>
            <Typography
              variant="body2"
              sx={{
                px: 1,
                bgcolor: blue[100],
                verticalAlign: "center",
                borderRadius: 1,
              }}
            >
              {t('related.link')}
            </Typography>
          </Link>
        </Box>
        <TaggedText label="Score" text={result.score} />
        <TaggedText label="By" text={result.author} />
        <TaggedText label="Date" text={result.answer_date} />
        <TaggedText label="Category" text={result.category} />
        <Box my={2}>
          <Typography gutterBottom variant="h4">
            Q.
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: question }} />
        </Box>
        <Box my={2}>
          <Typography gutterBottom variant="h4">
            A.
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: answer }} />
        </Box>
      </CardContent>
    </Card>
  );
};

PediatricsResultCard.propTypes = {
  result: PropTypes.object.isRequired,
};

export default PediatricsResultCard;
