import { Box, Grid, Link, Typography } from "@mui/material";
import Hero from "../components/top-page/Hero";

import DemoSummary from "../components/top-page/DemoSummary";
import Footer from "../components/reusable/Footer";
import TopBar from "../components/TopBar";
import pediatricsImage from "../static/demo-logos/pediatrics.svg";
import textSearchImage from "../static/demo-logos/text-search.svg";
import imageSearchImage from "../static/demo-logos/image-search.svg";
import textToImageImage from "../static/demo-logos/text-to-image.svg";
import carSearchImage from '../static/demo-logos/car-search.svg'
import idiomsImage from "../static/demo-logos/idioms.svg";
import manualSearch from '../static/demo-logos/manual-search.svg';
import patentSearch from '../static/demo-logos/patent-search.svg';
import { useTranslation, Trans } from "react-i18next";
import { localizedRoute } from "../components/utils";

const TopPage = () => {
  const { t, i18n } = useTranslation('topPage')
  const items = [
    {
      title: t('titles.news'),
      description: t('descriptions.news'),
      image: textSearchImage,
      link: localizedRoute(i18n.language, "/text-search"),
    },
    {
      title: t('titles.image'),
      description: t('descriptions.image'),
      image: imageSearchImage,
      link: localizedRoute(i18n.language, "/image-search"),
    },
    {
      title: t('titles.textToImage'),
      description: t('descriptions.textToImage'),
      image: textToImageImage,
      link: localizedRoute(i18n.language, "/text-to-image"),
    },
    {
      title: t('titles.idiom'),
      description: t('descriptions.idiom'),
      image: idiomsImage,
      link: localizedRoute(i18n.language, "/idioms-search"),
    },
    {
      title: t('titles.pediatrics'),
      description: t('descriptions.pediatrics'),
      image: pediatricsImage,
      link: localizedRoute(i18n.language, "/pediatrics-search"),
    },
    {
      title: t('titles.cars'),
      description: t('descriptions.cars'),
      image: carSearchImage,
      link: localizedRoute(i18n.language, "/car-search")
    },
    {
      title: t('titles.solrManual'),
      description: t('descriptions.solrManual'),
      image: manualSearch,
      link: localizedRoute(i18n.language, "/solr-manual")
    },
    {
      title: t('titles.patentData'),
      description: t('descriptions.patentData'),
      image: patentSearch,
      link: localizedRoute(i18n.language, "/patent-image-search")
    }
  ];


  return (
    <>
      <TopBar />
      <Box mt={-2}>
        <Box height={450}>
          <Hero />
        </Box>
        <Typography variant="h3" align="center" paddingTop={4} paddingX={0.75}>
          <Trans i18nKey="topPage:promo" components={[<Link sx={{ textDecoration: "none" }} href="https://kandasearch.com/" target="_blank" />]} />
        </Typography>
        <Grid container spacing={2} mt={2} padding={2}>
          {items.map((item) => (
            <DemoSummary key={item.title} demo={item} />
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default TopPage;
