import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import generalEN from './translation/en/general.json'
import generalJA from './translation/ja/general.json'
import topPageEN from './translation/en/topPage.json'
import topPageJA from './translation/ja/topPage.json'
import textSearchPageEN from './translation/en/textSearchPage.json'
import textSearchPageJA from './translation/ja/textSearchPage.json'
import textToImagePageEN from './translation/en/textToImagePage.json'
import textToImagePageJA from './translation/ja/textToImagePage.json'
import imageSearchPageEN from './translation/en/imageSearchPage.json'
import imageSearchPageJA from './translation/ja/imageSearchPage.json'
import idiomSearchPageEN from './translation/en/idiomSearchPage.json'
import idiomSearchPageJA from './translation/ja/idiomSearchPage.json'
import patentImageSearchPageEN from './translation/en/patentImageSearchPage.json'
import patentImageSearchPageJA from './translation/ja/patentImageSearchPage.json'
import pediatricsSearchPageEN from './translation/en/pediatricsSearchPage.json'
import pediatricsSearchPageJA from './translation/ja/pediatricsSearchPage.json'
import notFoundPageEN from "./translation/en/notFoundPage.json"
import notFoundPageJA from './translation/ja/notFoundPage.json'
import carSearchPageEN from './translation/en/carSearchPage.json'
import carSearchPageJA from './translation/ja/carSearchPage.json'
import solrManualPageEN from './translation/en/solrManualPage.json'
import solrManualPageJA from './translation/ja/solrManualPage.json'

const resources = {
    en: {
        general: generalEN,
        notFoundPage: notFoundPageEN,
        topPage: topPageEN,
        carSearchPage: carSearchPageEN,
        textSearchPage: textSearchPageEN,
        textToImagePage: textToImagePageEN,
        imageSearchPage: imageSearchPageEN,
        idiomSearchPage: idiomSearchPageEN,
        patentImageSearchPage: patentImageSearchPageEN,
        pediatricsSearchPage: pediatricsSearchPageEN,
        solrManualPage: solrManualPageEN
    },
    ja: {
        general: generalJA,
        notFoundPage: notFoundPageJA,
        topPage: topPageJA,
        carSearchPage: carSearchPageJA,
        textSearchPage: textSearchPageJA,
        textToImagePage: textToImagePageJA,
        imageSearchPage: imageSearchPageJA,
        idiomSearchPage: idiomSearchPageJA,
        patentImageSearchPage: patentImageSearchPageJA,
        pediatricsSearchPage: pediatricsSearchPageJA,
        solrManualPage: solrManualPageJA
    }
}

i18n
    .use(initReactI18next)
    .init({ 
        resources,
        lng: "ja",
        interpolation: {
            escapeValue: false
        }
    })

export default i18n 
