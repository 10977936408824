import { Box, Button, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { grey } from "@mui/material/colors";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from 'prop-types'

const uploadStyle = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  marginTop: 4,
  marginBottom: 4,
  paddingTop: 4,
  paddingBottom: 4,
  border: `2px dashed grey`,
  borderRadius: 1,
  "& > *": {
    margin: "0.5",
  },
};

const UploadFileSection = ({ image, open, onSearch }) => {
  const { t } = useTranslation('imageSearchPage')

  return (
    <Box sx={uploadStyle} bgcolor={grey[100]}>
      <Typography variant="h5" fontWeight="600" mb={2}>
        {t('search.upload')}
      </Typography>
      {!!image && (
        <Box>
          <img
            style={{ height: "220px", objectFit: "cover", width: "220px" }}
            src={URL.createObjectURL(image[0])}
            alt={image[0].name}
          />
        </Box>
      )}
      <Box mb={4}>
        <Button
          variant="contained"
          onClick={open}
          aria-label="select upload file"
          endIcon={<FileUploadIcon />}
        >
          {t('search.choose')}
        </Button>
        {!!image && (
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            endIcon={<ImageSearchIcon />}
            onClick={() => onSearch(image)}
          >
            {t('search.image')}
          </Button>
        )}
      </Box>
      <Typography variant="caption">{t('search.support')}</Typography>
      <Typography variant="caption">
        <Trans ns="imageSearchPage" i18nKey="search.note" components={[<strong />]} />
      </Typography>
    </Box>
  );
};

UploadFileSection.propTypes = {
  image: PropTypes.array,
  open: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
}

export default UploadFileSection;
