import { Box, Button, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

const Title = ({ t }) => (
    <Typography variant='h5' mb={1}>
        {t('welcome.title')}
    </Typography>
)

const Body = ({ t, example1Search, example2Search, example3Search }) => {
    return (
    <>
        <Typography variant="body1" mb={1} display="flex" alignItems="center">
          {t('welcome.intro')}
        </Typography>        
        <Typography variant='body1'>
          {t('welcome.howToUse')}
        </Typography>
        <Typography variant='body1'>
          {t('welcome.description1')}
        </Typography>
        <Box my={2}>
          <Button variant='text' onClick={example1Search}>{t('welcome.example1')}</Button>
          <Button variant='text' onClick={example2Search}>{t('welcome.example2')}</Button>
          <Button variant='text' onClick={example3Search}>{t('welcome.example3')}</Button>
        </Box>
        <Typography variant='body1'>
          {t('welcome.description2')}
        </Typography>
    </> 
    )
}

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('carSearchPage')(Title)
export const DescriptionBody = withTranslation('carSearchPage')(Body)