import { Button, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

const Title = ({ t }) => (
  <Typography variant="h5" mb={1}>
    {t('welcome.title')}
  </Typography>
);


const Body = ({
  t,
  example1Search,
  example2Search,
  example3Search,
  example4Search,
}) => (
  <>
    <Typography variant="body1" mb={1} display="flex" alignItems="center">
      <Trans ns="textSearchPage" i18nKey="welcome.source" components={[<Link href="https://www.rondhuit.com/download.html#ldcc" target="_blank"/>, <Link href="https://www.kaggle.com/datasets/rmisra/news-category-dataset" target="_blank" />]} />    
    </Typography>
    <Typography variant="body1">{t('welcome.howTo')}</Typography>
    <Typography variant="body2" gutterBottom>
      {t('welcome.subtitle1')}
    </Typography>
    <Typography variant="body1" mb={2}>
      <Trans ns="textSearchPage" i18nKey="welcome.description1" components={[
        <Button variant="text" style={{ padding: 0 }} onClick={example1Search} />,
        <Button variant="text" style={{ padding: 0 }} onClick={example3Search} />,
        <Button variant="text" style={{ padding: 0 }} onClick={example4Search} />,
        <Button variant="text" style={{ padding: 0 }} onClick={example3Search} />
      ]} />
    </Typography>
    <Typography variant="body2" gutterBottom>
      {t('welcome.subtitle2')}
    </Typography>
    <Typography variant="body1" mb={2}>
      <Trans ns="textSearchPage" i18nKey="welcome.description2" components={[
        <Button variant="text" style={{ padding: 0 }} onClick={example2Search} />
      ]} />
    </Typography>
  </>
);

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('textSearchPage')(Title)
export const DescriptionBody = withTranslation('textSearchPage')(Body)
