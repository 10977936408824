import { Box, Card, CardContent, Link, Typography, useMediaQuery, useTheme } from "@mui/material"
import { blue } from "@mui/material/colors"
import { useTranslation } from "react-i18next"
import { capitalize, localizedRoute } from "../utils"
import TaggedText from "../reusable/TaggedText";
import Image from "../reusable/Image";
import SummaryText from "../reusable/SummaryText";

const CarResultCard = ({ result }) => {
  const { t, i18n } = useTranslation('carSearchPage')
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

    return (
    <Card sx={{ my: 3 }} raised>
        <CardContent>
          <Box display='flex' flexDirection={isLargeScreen ? 'row' : 'column-reverse'} justifyContent='space-between' gap={2}>
            <Box display='flex' flexDirection={isLargeScreen ? 'row' : 'column'} alignItems={isLargeScreen ? 'flex-start' : 'center'} my={3}>
              <Image image={result.og_image} />
              <Box flexDirection='column' ml={2}>
                <TaggedText label="Maker" text={capitalize(result.maker_en)} />
                <TaggedText label="Model" text={result.model_en} />
                <TaggedText label="Score" text={result.score} />
              </Box>
            </Box>
            <Link href={localizedRoute(i18n.language, `/car-search/${result.id}`)}>
              <Typography
                variant="body2"
                sx={{
                  px: 1,
                  bgcolor: blue[100],
                  verticalAlign: "center",
                  borderRadius: 1,
                  textAlign: isLargeScreen ? 'start' : 'center'
                }}
              >
                {t('related.link')}
              </Typography>
            </Link>
          </Box>    
        <Box>
          <SummaryText
            positive={result.summary_eval_like.join()}
            negative={result.summary_eval_dislike.join()}
            full={result.summary_eval_comprehensive.join()}
          />
        </Box>
      </CardContent>
    </Card>
    )
}

export default CarResultCard