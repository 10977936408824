import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { carRelatedSearch, carSearch } from "../api/api"
import CarResults from "../components/car-page/CarResults"
import Loading from "../components/reusable/Loading"
import SimpleSearchBar from "../components/reusable/SimpleSearchBar"
import { formatSearchTerm } from "../components/utils"

const CarRelatedPage = () => {
    const { carId } = useParams()

    const [searchQuery, setSearchQuery] = useState({})
    const [results, setResults] = useState(null)
    const [related, setRelated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState([])

    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            query: ''
        }
    })

    useEffect(() => {
        if (filters.length === 0) return
        setFilters([])
        filters.map(f => setValue(f, undefined))
    // eslint-disable-next-line
    }, [watch('query')])

    const handleRelatedSearch = async (carId) => {
        const body = { id: `${carId}` }
        setRelated(true)
        setLoading(true)
        try {
            const { data: carRes } = await carRelatedSearch(body)
            setResults(carRes)
        } catch (error) {
            setResults({ docs: [] })
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!carId) return
        handleRelatedSearch(carId)
    // eslint-disable-next-line
    }, [carId])

    const doSearch = async (searchParams) => {
        setLoading(true)
        const { query, offset, filters } = searchParams
        const formattedSearchTerm = formatSearchTerm(query)
        const body = {
            ...searchParams,
            query: formattedSearchTerm,
            offset: offset,
            filters: filters
        }

        try {
            const { data: carRes } = await carSearch(body)
            setResults(carRes)
            if (carRes.docs.length === 0) {
                setFilters([])
                filters.map(f => setValue(f, undefined))
            }
        } catch (error) {
            console.log(error)
            setResults({ docs: [] })
            setFilters([])
        }
        setLoading(false)
    }

    const handleManualSearch = async (searchParams) => {
        setRelated(false)
        searchParams.offset = 1
        searchParams.filters = filters
        setSearchQuery(searchParams)

        await doSearch(searchParams)
    }

    const handleFacetClick = (checked, filter) => {
        const newFilters = checked
          ? [...filters, filter]
          : filters.filter((f) => f !== filter);

        setFilters(newFilters);
      };

      const handlePageClick = (pageNum) => {
        const searchParams = { ...searchQuery, offset: pageNum };
        setSearchQuery(searchParams);

        doSearch(searchParams);
      };
    
    return (
        <>
            <SimpleSearchBar
                noKeyword
                control={control}
                handleSubmit={handleSubmit}
                onSearch={handleManualSearch}
            />

            {loading && <Loading />}
            {results && !loading && (
                <CarResults
                    searchQuery={searchQuery}
                    control={control}
                    related={related}
                    results={results}
                    onPageClick={handlePageClick}
                    onFacetClick={handleFacetClick}
                />
            )}
        </>
    )
}

export default CarRelatedPage