import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useDropzone } from "react-dropzone";

import DescriptionBox from "../components/reusable/DescriptionBox";
import UploadFileSection from "../components/image-search-page/UploadFileSection";
import { patentImageSearch } from "../api/api";
import {
  DescriptionBody,
  DescriptionTitle,
} from "../components/patent-image-search-page/PageDescriptions";
import Loading from "../components/reusable/Loading";
import ImageResults from "../components/reusable/ImageResults";

const PatentImageSearchPage = () => {
  const onDrop = useCallback((file) => {
    setImage(file);
  }, []);

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [accordion, setAccordion] = useState(true);

  const scrollToLoading = () => {
    const loadingElem = document.querySelector("#loading");

    if (!loadingElem) return;
    loadingElem.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => scrollToLoading(), [loading]);

  const handleSearch = async (file) => {
    setLoading(true);
    try {
      const body = new FormData();
      body.append("image", new Blob(file));

      const { data: imageResults } = await patentImageSearch(body);
      setResults(imageResults);
    } catch (error) {
      console.log(error);
      setResults({ docs: [] });
    }
    setLoading(false);
  };

  const handleAccordionToggle = () => setAccordion(!accordion);

  const handleAutoSearch = async (image) => {
    const url = image;
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const file = new File([response.data], "image", {
        type: response.data.type,
      });

      setImage([file]);

      accordion && handleAccordionToggle();
      handleSearch([file]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DescriptionBox
        title={<DescriptionTitle />}
        body={<DescriptionBody imageSearch={handleAutoSearch} />}
        expanded={accordion}
        onAccordionChange={handleAccordionToggle}
      />

      <div {...getRootProps({ className: "dropzone" })}>
        <UploadFileSection image={image} open={open} onSearch={handleSearch} />
        <input {...getInputProps()} />
      </div>

      {loading && <Loading />}

      {results && !loading && (
        <ImageResults results={results} imageSearch={handleAutoSearch} patent/>
      )}
    </>
  );
};

export default PatentImageSearchPage
