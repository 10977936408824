import { Grid, Link, Typography } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";
import { patentImageList } from './imageSearchUtils'

const Title = ({ t }) => (
  <Typography variant="h5" mb={1}>
    {t('welcome.title')}
  </Typography>
);

const Body = ({ t, imageSearch}) => {
  return (
    <>
      <Typography variant="body1" mb={1} display="flex" alignItems="center">
        <Trans ns="patentImageSearchPage" i18nKey="welcome.source" components={[
            <Link href="https://www.jpo.go.jp/system/laws/sesaku/data/download.html" target='_blank' />
        ]} />
      </Typography>
      <Typography variant="body1">
        {t('welcome.howTo')}
      </Typography>
      <Typography variant="body1" mb={2}>
        <Trans ns="patentImageSearchPage" i18nKey="welcome.description" components={[
            <Link href="https://www.j-platpat.inpit.go.jp" target='_blank' />
        ]} />
      </Typography>

      <Grid container>
        {patentImageList.map((item, i) => (
          <Grid display="flex" item xs={12} sm={6} mb={2} key={i}>
            <img
              src={item.image}
              alt={item.text}
              height="100px"
              style={{ cursor: "pointer" }}
              onClick={() => imageSearch(item.image)}
            />
            <Typography variant="body1" paddingX={1}>
              {t(item.text)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

// withTranslation is i18next's HOC that allows you to pass localization into a component
// the above components have a property 't' that withTranslation passes a value to, to do translation
// this is used so components are updated when a user changes the site language. 
export const DescriptionTitle = withTranslation('patentImageSearchPage')(Title)
export const DescriptionBody = withTranslation('patentImageSearchPage')(Body)