import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import CarResults from "../components/car-page/CarResults"
// import CarSearchChart from '../components/car-page/CarSearchChart'
import { DescriptionBody, DescriptionTitle } from "../components/car-page/PageDescriptions"
import DescriptionBox from "../components/reusable/DescriptionBox"
import Loading from "../components/reusable/Loading"
import SimpleSearchBar from "../components/reusable/SimpleSearchBar"
import { average, dataRadius, formatSearchTerm } from "../components/utils";
import { carSearch } from "../api/api"
import dummyImage from '../static/dummy-200.png'

const CarPage = () => {
    const [accordion, setAccordion] = useState(true)
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState(null)
    const [results, setResults] = useState(null)
    const [filters, setFilters] = useState([])
    const [chartData, setChartData] = useState(null)

    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            query: ''
        }
    })

    useEffect(() => {
        if (filters.length === 0) return
        setFilters([])
        filters.map(f => setValue(f, false))
    // eslint-disable-next-line
    }, [watch('query')])

    const doSearch = async (searchParams) => {
        setLoading(true)
        const { query, offset, filters } = searchParams
        const formattedSearchTerm = formatSearchTerm(query)
        
        const body = {
            query: formattedSearchTerm,
            offset: offset,
            filter: filters
        }

        try {
            const { data: carRes } = await carSearch(body)
            setResults(carRes)
            if (carRes.docs.length === 0) {
                setFilters([])
                filters.map(f => setValue(f, false))
            }
        } catch (error) {
            console.log(error)
            setResults({ docs: [], numFound: 0 })
            setFilters([])
        }
        setLoading(false)
    }

    const handleAutoSearch = (queryTerms) => {
        setValue("query", queryTerms);
        const searchParams = {
            offset: 1,
            query: queryTerms,
            filters: []
        }
        setSearchQuery(searchParams)

        doSearch(searchParams);
        setAccordion(false)

    };

    const handleExample1Click = () => (
        handleAutoSearch("家族と買い物に行くのに便利な車")
    )

    const handleExample2Click = () => (
        handleAutoSearch("仲間とキャンプに行きたい")
    )

    const handleExample3Click = () => (
        handleAutoSearch("とにかくかっこいいスポーツカー")
    )

    const handleManualSearch = async (searchParams) => {
        searchParams.offset = 1
        searchParams.filters = filters

        setSearchQuery(searchParams)
        await doSearch(searchParams)
    }

    const handlePageClick = async (pageNum) => {
        const searchParams = { ...searchQuery, offset: pageNum };
        setSearchQuery(searchParams);

        doSearch(searchParams);
    };

    const handleFacetClick = (checked, filter) => {
        const newFilters = checked
            ? [...filters, filter]
            : filters.filter((f) => f !== filter);

        setFilters(newFilters);
    };

    const handleAccordionToggle = () => setAccordion(!accordion)

    const handleGetChartData = () => {
        // placeholders
        const searches = [
            ['cool', 'Toyota Camry'],
            ['eco-friendly', 'Tesla'],
            ['sporty', 'Toyota Prius'],
            ['cute', 'BMW Beamer']
        ]

        const dataPoints = searches.map(search => {
            return (
                Array.from({ length: 25 }, (_, i) => ({
                    x: Math.floor(Math.random() * 100),
                    y: Math.floor(Math.random() * 100),
                    name: search[1] + i,
                    img: dummyImage
                }))
            )
        })

        const bubblePoints = dataPoints.map(dataset => {
            const xCord = average(dataset, 'x')
            const yCord = average(dataset, 'y')
            const radius = dataRadius(dataset, xCord, yCord)

            return ({
                x: xCord,
                y: yCord,
                r: radius
            })
        })

        setChartData({ dataPoints: dataPoints, bubbles: bubblePoints })
    }

    useEffect(() => {
        handleGetChartData()
    }, [])

    return (
        <>
            <DescriptionBox
                expanded={accordion}
                onAccordionChange={handleAccordionToggle}
                title={<DescriptionTitle />}
                body={
                    <DescriptionBody
                        example1Search={handleExample1Click}
                        example2Search={handleExample2Click}
                        example3Search={handleExample3Click}
                    />
                }
            />
            {/* <CarSearchChart chartData={chartData} /> */}
            <SimpleSearchBar
                noKeyword
                control={control}
                handleSubmit={handleSubmit}
                onSearch={handleManualSearch}
            />

            {loading && <Loading />}
            {results && !loading && (
                <CarResults
                    searchQuery={searchQuery}
                    control={control}
                    related={false}
                    results={results}
                    onPageClick={handlePageClick}
                    onFacetClick={handleFacetClick}
                />
            )}
        </>
    )
}

export default CarPage