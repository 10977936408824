import { formatSearchTerm } from "../utils";
import {
  COLLECTIONS,
  HUFFPOST_VECTOR,
  LIVEDOOR_VECTOR,
  MODEL,
} from "./constants";

export const createRequestBody = (searchParams) => {
  const { query, keywordSearch, collection, offset, filters } = searchParams;

  const formattedSearchTerm = formatSearchTerm(query);

  const title = collection === COLLECTIONS.LIVEDOOR ? "title" : "headline";
  const body =
    collection === COLLECTIONS.LIVEDOOR ? "body" : "short_description";
  const url = collection === COLLECTIONS.LIVEDOOR ? "url" : "link";
  const model =
    collection === COLLECTIONS.LIVEDOOR ? MODEL.LIVEDOOR : MODEL.HUFFPOST;
  const target =
    collection === COLLECTIONS.LIVEDOOR
      ? LIVEDOOR_VECTOR.value
      : HUFFPOST_VECTOR.value;

  const requestBody = {
    q: formattedSearchTerm,
    q_original: query,
    collection: collection,
    keyword: keywordSearch,
    target: target,
    model: model,
    title: title,
    body: body,
    url: url,
    offset: offset,
    filter: filters,
  };

  return requestBody;
};

export const formatResponse = (collection, response) => {
  if (collection === COLLECTIONS.LIVEDOOR) return response;

  const hl = response.hl

  const formattedResponse = response.docs.map((doc) => {
    const title = doc.headline[0];
    const body = doc.short_description.join();
    const authors = doc.authors[0];

    if (response.hl[doc.id]?.headline) {
      hl[doc.id].title = response.hl[doc.id].headline
    }
    
    if (response.hl[doc.id]?.short_description) {
      hl[doc.id].body = response.hl[doc.id].short_description
    }
    

    return { ...doc, title: title, body: body, authors: authors };
  });

  response.docs = formattedResponse;
  response.hl = hl

  return response;
};
