export const formatSearchTerm = (searchTerm) => {
  return searchTerm.trim() === "" ? "*:*" : searchTerm;
};

export const formatResultBody = (result, hl) => {
  return hl?.body
    ? hl.body
        .join(",")
        .replaceAll("<em>", "<mark>")
        .replaceAll("</em>", "</mark>")
    : typeof result === "string"
      ? `${result}...`
      : `${result.slice(0, 5)}...`;
};

export const formatFieldResult = (result, hl, field) => {
  return hl?.[field]
    ? hl[field]
        .join(",")
        .replaceAll("<em>", "<mark>")
        .replaceAll("</em>", "</mark>")
    : `${result}`;
};

export const localizedRoute = (lang, path) => {
  // this prevents trailing slashes on home page with EN locale
  if (path === '/' && lang === 'en') return ''
  return lang === 'en' ? `/en${path}` : path
}

export const localeChangePath = (lang, path) => {
  // handles home page routing
  if (path === '/' && lang === 'ja') return '/en'
  if (path === '/en' && lang === 'en') return '/'
  
  const splitPath = path.split('/en')
  const basePath = splitPath.length > 1 
    ? splitPath[1]
    : splitPath[0]

  return lang === 'ja' ? `/en${basePath}`: basePath
}

export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl
}

export const createTableTitle = (tableHeadEl, data) => {
  const tr = document.createElement('tr')
  const th = document.createElement('th')
  const text = document.createTextNode(data.raw.name)

  th.style.borderWidth = 0
  tr.style.borderWidth = 0

  th.appendChild(text)
  tr.appendChild(th)
  tableHeadEl.appendChild(tr)
}

export const createTableBody = (tableBodyEl, data) => {
  const img = document.createElement('img')
  img.src = data.raw.img

  const tr = document.createElement('tr')
  const td = document.createElement('td')
              
  td.appendChild(img)
  tr.appendChild(td)
  tableBodyEl.appendChild(tr)
}

export const average = (dataset, axis) => {
  const coords = dataset.map(data => data[axis])
  const avg = coords.reduce((prev, curr) => prev + curr, 0) / coords.length
  return avg
}

export const dataRadius = (dataset, xAvg, yAvg) => {
  const distances = dataset.map(data => Math.sqrt((data.x - xAvg) ** 2 + (data.y - yAvg) ** 2))
  const radius = distances.reduce((prev, curr) => prev + curr, 0) / distances.length
  return radius
}