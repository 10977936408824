import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const tagStyle = {
  display: "inline-block",
  fontSize: "0.75em",
  fontWeight: "bold",
  backgroundColor: "success.light",
  opacity: 0.7,
  color: "white",
  borderRadius: "0.25rem",
  paddingY: "0.15em",
  paddingX: "0.45em",
  marginRight: "0.5em",
};

const TaggedText = ({ label, text }) => {
  return (
    <Box mt={0.5}>
      <Typography sx={tagStyle} component="span" align="center">
        {label}
      </Typography>
      {text}
    </Box>
  );
};

TaggedText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TaggedText;
