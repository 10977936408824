import image1 from "../../static/search-images/search-image-1.jpeg";
import image2 from "../../static/search-images/search-image-2.jpeg";
import image3 from "../../static/search-images/search-image-3.jpeg";
import image4 from "../../static/search-images/search-image-4.jpeg";
import image5 from "../../static/search-images/search-image-5.png";
import image6 from "../../static/search-images/search-image-6.png";

export const imageList = [
  {
    image: image1,
    text: 'welcome.image1',
  },
  {
    image: image2,
    text: 'welcome.image2',
  },
  {
    image: image3,
    text: 'welcome.image3',
  },
  {
    image: image4,
    text: 'welcome.image4',
  },
  {
    image: image5,
    text: 'welcome.image5',
  },
  {
    image: image6,
    text: 'welcome.image6',
  },
];
