import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TaggedText from "../reusable/TaggedText";

const PediatricsRelatedResultCard = ({ result }) => {
  const { t } = useTranslation('pediatricsSearchPage')
  return (
    <Accordion
      sx={{ my: 3 }}
      defaultExpanded={false}
      component={Paper}
      elevation={2}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">{t('related.original')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" alignItems="center">
          <Link href={result.id} sx={{ textDecorationLine: "none" }}>
            <Typography variant="h4" gutterBottom>
              {result.title}
            </Typography>
          </Link>
        </Box>
        <TaggedText label="Score" text={result.score} />
        <TaggedText label="By" text={result.author} />
        <TaggedText label="Date" text={result.answer_date} />
        <TaggedText label="Category" text={result.category} />
        <Box my={2}>
          <Typography gutterBottom variant="h4">
            Q.
          </Typography>
          <Typography>{result.question}</Typography>
        </Box>
        <Box my={2}>
          <Typography gutterBottom variant="h4">
            A.
          </Typography>
          <Typography>{result.answer}</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

PediatricsRelatedResultCard.propTypes = {
  result: PropTypes.object.isRequired,
};

export default PediatricsRelatedResultCard;
