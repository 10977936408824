import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { blue, green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const tagStyle = (bgColor) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: "0.75em",
  fontWeight: "bold",
  backgroundColor: bgColor,
  opacity: 0.7,
  color: "white",
  borderRadius: "0.25rem",
  paddingY: "0.3em",
  paddingX: "0.45em",
  marginTop: "0.5em"
});

const SummaryText = ({ positive, negative, full }) => {
    const { t } = useTranslation('carSearchPage')
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

    return isLargeScreen ? (
        <Box display='flex' gap={2}>
            <Box width='33%'>
                <Typography sx={tagStyle('info.main')}>
                    <ThumbUpIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.positiveSummary')}
                </Typography>
                <Typography whiteSpace='pre-wrap'>
                    {positive.trim()}
                </Typography>
            </Box>
            <Box width='33%'>
                <Typography sx={tagStyle('error.main')} align="center">
                    <ThumbDownIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.negativeSummary')}
                </Typography>
                <Typography whiteSpace='pre-wrap'>
                    {negative.trim()}
                </Typography>
            </Box>
            <Box width='33%'>
                <Typography sx={tagStyle('success.light')} align="center">
                    <ReviewsIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.fullSummary')}
                </Typography>
                {full}
            </Box>
        </Box>
    ) : (
        <div>
            <Accordion>
                    <AccordionSummary sx={{ bgcolor: blue[300], color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <ThumbUpIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.positiveSummary')}
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: blue[50] }}>
                    <Typography whiteSpace='pre-wrap'>
                        {positive.trim()}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary sx={{ bgcolor: red[300], color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <ThumbDownIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.negativeSummary')}
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: red[50] }}>
                    <Typography whiteSpace='pre-wrap'>
                        {negative.trim()}
                    </Typography>
                </AccordionDetails>
                </Accordion>
            <Accordion>
                <AccordionSummary sx={{ bgcolor: green[300], color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <ReviewsIcon fontSize='small' sx={{ mr: 1 }} /> {t('results.fullSummary')}
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: green[50] }}>
                    <Typography whiteSpace='pre-wrap'>
                        {full.trim()}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SummaryText