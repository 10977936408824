import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

const DescriptionBox = ({ expanded, onAccordionChange, title, body }) => {
  return (
    <Accordion expanded={expanded} onChange={onAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
};

DescriptionBox.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
};

export default DescriptionBox;
