import styled from "@emotion/styled";
import { Typography, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import NoContent from "../reusable/NoContent";
import Faceting from "../reusable/Faceting";
import PediatricsResultCard from "./PediatricsResultCard";
import PediatricsRelatedResultCard from "./PediatricsRelatedResultCard";
import { Trans, useTranslation } from "react-i18next";

const SearchInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const PediatricsResults = ({
  searchQuery,
  results,
  related,
  control,
  onFacetClick,
  onPageClick,
}) => {
  const { t } = useTranslation('pediatricsSearchPage')
  const { query, keywordSearch, offset, filters } = searchQuery;
  const { docs, numFound, facets, hl } = results;

  const totalPages = Math.ceil(results.numFound / 10);

  const handlePageClick = (_, value) => onPageClick(value);

  return (
    <Box mt={2}>
      {docs.length > 0 ? (
        <>
          {facets && Object.keys(facets).length > 0 && (
            <Faceting
              facets={facets}
              onFacetClick={onFacetClick}
              control={control}
            />
          )}

          <SearchInfoBox>
            {related ? (
              <Typography variant="h4">
                {t('results.related')}
              </Typography>
            ) : (
              <Typography variant="h4">
                {t('results.query', { query: query })}
              </Typography>
            )}
            <Typography variant="body1">
              {t('results.docs', { num: numFound })}
            </Typography>
          </SearchInfoBox>

          {related
            ? docs.map((result, i) =>
                i === 0 ? (
                  <PediatricsRelatedResultCard
                    key={result.id}
                    result={result}
                  />
                ) : (
                  <PediatricsResultCard
                    key={result.id}
                    result={result}
                    hl={hl?.[result.id]}
                  />
                )
              )
            : docs.map((result) => (
                <PediatricsResultCard
                  key={result.id}
                  result={result}
                  hl={hl?.[result.id]}
                />
              ))}

          {totalPages > 1 && keywordSearch && (
            <Box display="flex" justifyContent="center" mb={1}>
              <Pagination
                count={totalPages}
                variant="outlined"
                size="large"
                page={offset}
                onChange={handlePageClick}
              />
            </Box>
          )}
        </>
      ) : (
        <NoContent>
          {related ? (
            t('results.noneRelated')
          ) : (
            <>
              <Trans ns="pediatricsSearchPage" i18nKey="results.none" values={{ query: query }} components={[
                <strong />
              ]} />
              {filters.length > 0 && (
                <Typography component="p" variant="caption">
                  {t('results.filterReset')}
                </Typography>
              )}
            </>
          )}
        </NoContent>
      )}
    </Box>
  );
};

PediatricsResults.propTypes = {
  query: PropTypes.string,
  results: PropTypes.object,
  image: PropTypes.bool,
  related: PropTypes.bool,
  control: PropTypes.object,
};

export default PediatricsResults;
