import { useState } from "react";
import { useForm } from "react-hook-form";
import { textToImageSearch } from "../api/api";
import DescriptionBox from "../components/reusable/DescriptionBox";
import ImageResults from "../components/reusable/ImageResults";
import Loading from "../components/reusable/Loading";
import SimpleSearchBar from "../components/reusable/SimpleSearchBar";
import {
  DescriptionBody,
  DescriptionTitle,
} from "../components/text-to-search-page/PageDescriptions";
import { formatSearchTerm } from "../components/utils";

const TextToImagePage = () => {
  const [searchTerms, setSearchTerms] = useState(null);
  const [results, setResults] = useState(null);
  const [accordion, setAccordion] = useState(true);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      query: "",
      keywordSearch: false,
    },
  });

  const doSearch = async (searchParams) => {
    setLoading(true);
    const { query } = searchParams;
    const formattedSearchTerm = formatSearchTerm(query);

    const body = { q: formattedSearchTerm };

    try {
      const { data: imageResults } = await textToImageSearch(body);

      accordion && setAccordion(false);

      setSearchTerms(query);
      setResults(imageResults);
    } catch (error) {
      console.log(error);
      setSearchTerms(query);
      setResults({ docs: [] });
    }
    setLoading(false);
  };

  const handleManualSearch = (searchParams) => doSearch(searchParams);

  const handleAutoSearch = (query) => {
    const searchParams = { query: query };

    setValue("query", query);
    doSearch(searchParams);
  };

  const handleAccordionToggle = () => setAccordion(!accordion);

  return (
    <>
      <DescriptionBox
        expanded={accordion}
        onAccordionChange={handleAccordionToggle}
        title={<DescriptionTitle />}
        body={<DescriptionBody onExampleClick={handleAutoSearch} />}
      />

      <SimpleSearchBar
        control={control}
        handleSubmit={handleSubmit}
        onSearch={handleManualSearch}
        noKeyword
      />

      {loading && <Loading />}

      {results && !loading && (
        <ImageResults searchQuery={searchTerms} results={results} />
      )}
    </>
  );
};

export default TextToImagePage;
