import { useEffect, useState } from "react"
import DescriptionBox from "../components/reusable/DescriptionBox"
import Loading from "../components/reusable/Loading"
import ManualSearchResults from "../components/solr-manual-page/ManualSearchResults"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DescriptionTitle, DescriptionBody } from "../components/solr-manual-page/PageDescriptions"
import { schema, defaults } from "../components/solr-manual-page/searchBarSchema"
import { useTranslation } from "react-i18next"
import { solrManualSearch } from "../api/api"
import SimpleSearchBar from "../components/reusable/SimpleSearchBar"

const SolrManualPage = () => {
    const { t, i18n } = useTranslation('solrManualPage')

    const [accordion, setAccordion] = useState(true)
    const [filters, setFilters] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState(null)
    const [results, setResults] = useState(null)

    const {
        control,
        handleSubmit,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });
    
    useEffect(() => {
        if (filters.length === 0) return;
        setFilters([]);
        filters.map((f) => setValue(f, undefined));
    // eslint-disable-next-line
    }, [watch("query")]);

    const doSearch = async (searchParams) => {
        setLoading(true);
        try {
            const { data: searchResults } = await solrManualSearch(searchParams);

          if (searchResults.docs.length === 0) {
            setFilters([]);
            filters.map((f) => setValue(f, undefined));
          }
            
          accordion && setAccordion(false);
          setResults(searchResults);
        } catch (error) {
            console.log(error);
            setResults({ docs: [] });
            setFilters([]);
        }
        setLoading(false);
    };

    const handleManualSearch = async (searchParams) => {
        searchParams.offset = 1;
        searchParams.filters = filters;
        searchParams.language = i18n.language
        
        setSearchQuery(searchParams);
        doSearch(searchParams);
    };

    const handleAutoSearch = (queryTerms) => {
        const searchParams = {
            query: queryTerms,
            keywordSearch: false,
            offset: 1,
            filters: [],
            language: i18n.language
        };

        setValue("query", queryTerms);
        setSearchQuery(searchParams);

        doSearch(searchParams);
    };
    
    const handleExample1Click = () => handleAutoSearch(t('welcome.example1'))
    const handleExample2Click = () => handleAutoSearch(t('welcome.example2'))
    const handleExample3Click = () => handleAutoSearch(t('welcome.example3'))
    const handleExample4Click = () => handleAutoSearch(t('welcome.example4'))

    const handleAccordionToggle = () => setAccordion(!accordion)

    const handleFacetClick = (checked, filter) => {
        const newFilters = checked
            ? [...filters, filter]
            : filters.filter((f) => f !== filter);

        setFilters(newFilters);
    };

    const handlePageClick = async (pageNum) => {
        const searchParams = { ...searchQuery, offset: pageNum };
        setSearchQuery(searchParams);

        doSearch(searchParams);
    };

    return (
        <>
            <DescriptionBox
                expanded={accordion}
                onAccordionChange={handleAccordionToggle}
                title={<DescriptionTitle />}
                body={
                    <DescriptionBody
                        example1Search={handleExample1Click}
                        example2Search={handleExample2Click}
                        example3Search={handleExample3Click}
                        example4Search={handleExample4Click}
                    />
                }
            />

            <SimpleSearchBar
                control={control}
                onSearch={handleManualSearch}
                handleSubmit={handleSubmit}
            />

            {loading && <Loading />}

            {results && !loading && (
                <ManualSearchResults
                    searchQuery={searchQuery}
                    results={results}
                    control={control}
                    onPageClick={handlePageClick}
                    onFacetClick={handleFacetClick}
                />
            )}
        </>
    )
}

export default SolrManualPage