import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TaggedText from "../reusable/TaggedText";
import PropTypes from 'prop-types'

const sectionStyle = {
  display: "inline-block",
  width: 40,
  backgroundColor: "success.light",
  opacity: 0.7,
  color: "white",
  borderRadius: 1,
  paddingX: 0.4,
  paddingY: 0.1,
  marginRight: "0.5em",
};

const IdiomsResultCard = ({ result }) => {
  const { t } = useTranslation('idiomSearchPage')
  const sections = [
    {
      label: t('results.english'),
      valueKey: "english",
    },
    {
      label: t('results.origin'),
      valueKey: "origin",
    },
    {
      label: t('results.usage'),
      valueKey: "usage",
    },
  ];
  return (
    <Card sx={{ my: 3 }} raised>
      <CardContent>
        <Box display="flex">
          <Link href={result.id} sx={{ textDecorationLine: "none" }}>
            <Typography variant="h4" gutterBottom>
              {result.title}
            </Typography>
          </Link>
          <Typography variant="h4" gutterBottom sx={{ pl: 1 }}>
            {result.title_reading}
          </Typography>
        </Box>
        <TaggedText label="Score" text={result.score} />
        <Box my={2}>
          <Typography>{result.meaning}</Typography>
        </Box>
        {sections.map(
          (section) =>
            !!result?.[section.valueKey].trim() && (
              <Box key={section.label} mb={0.5}>
                <Typography
                  sx={sectionStyle}
                  variant="caption"
                  component="span"
                  align="center"
                >
                  {section.label}
                </Typography>
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{ __html: result[section.valueKey] }}
                />
              </Box>
            )
        )}
      </CardContent>
    </Card>
  );
};

IdiomsResultCard.propTypes = {
  result: PropTypes.object.isRequired
}

export default IdiomsResultCard;
