import { ThemeProvider } from "@mui/material";
import SearchPage from "./pages/SearchPage";
import ImageSearchPage from "./pages/ImageSearchPage";
import TextToImagePage from "./pages/TextToImagePage";
import IdiomsPage from "./pages/IdiomsPage";
import PediatricsPage from "./pages/PediatricsPage";
import PediatricsTunedPage from "./pages/PediatricsTunedPage";
import PediatricsRelatedPage from "./pages/PediatricsRelatedPage";
import PediatricsTunedRelatedPage from "./pages/PediatricsTunedRelatedPage";
import TopPageLayout from "./components/TopPageLayout";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import theme from "./styles/theme";
import TopPage from "./pages/TopPage";
import DemoLayout from "./components/DemoLayout";
import NotFoundPage from "./pages/NotFoundPage";
import ChartDemoPage from "./pages/ChartDemoPage";
import CarPage from "./pages/CarPage";
import CarRelatedPage from "./pages/CarRealtedPage";
import SolrManualPage from "./pages/SolrManualPage";
import PatentImageSearchPage from "./pages/PatentImageSearchPage";

function App() {
  const pageRoutes = [
    {
      path: "text-search",
      element: <SearchPage />,
    },
    {
      path: "image-search",
      element: <ImageSearchPage />,
    },
    {
      path: "text-to-image",
      element: <TextToImagePage />,
    },
    {
      path: "idioms-search",
      element: <IdiomsPage />,
    },
    {
      path: "pediatrics-search/:docGroup/:docId",
      element: <PediatricsRelatedPage />,
    },
    {
      path: "pediatrics-search",
      element: <PediatricsPage />,
    },
    {
      path: "pediatrics-search-tuned",
      element: <PediatricsTunedPage />,
    },
    {
      path: "pediatrics-search-tuned/:docGroup/:docId",
      element: <PediatricsTunedRelatedPage />,
    },
    {
      path: "car-search",
      element: <CarPage />
    },
    {
      path: "car-search/:carId",
      element: <CarRelatedPage />
    },
    {
      path: "solr-manual",
      element: <SolrManualPage />
    },
    {
      path: "patent-image-search",
      element: <PatentImageSearchPage />
    },
    {
      path: 'test',
      element: <ChartDemoPage />
    },
    {
      path: '*',
      element: <NotFoundPage />
    }
  ]

  const router = createBrowserRouter([
    {
      path: "/en",
      element: <TopPageLayout language='en' />,
      children: [
        {
          path: "/en",
          element: <TopPage />,
        }
      ],
    },
    {
      path: "/en/*",
      element: <DemoLayout language='en'/>,
      children: pageRoutes.map(route => route),
    },
    {
      path: "/",
      element: <TopPageLayout />,
      children: [
        {
          path: "/",
          element: <TopPage />,
        }
      ],
    },
    {
      path: "/*",
      element: <DemoLayout />,
      children: pageRoutes.map(route => route)
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
